module.exports = {
  theme: {
    container: {
      center: true,
      padding: '1.5rem',
    },

    fontFamily: {
      display: [
        'din-condensed',
        'system-ui',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        '"Noto Sans"',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"',
      ],
    },

    extend: {
      colors: {
        brand: '#F26522',
      },
    },
  },

  purge: ['./src/**/*.js', './src/**/*.module.css'],
};
