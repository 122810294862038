import Store from 'conf-store';
import camelcase from 'camelcase-keys';
import tailwindConfig from '../tailwind.config';

const env = process.env.NODE_ENV || 'development';

const store = new Store({
  env,

  // Expose TailwindCSS config values
  tailwind: camelcase(tailwindConfig, { deep: true }),

  siteUrl: 'https://smwhawaii.com',

  googleAnalytics: {
    trackingId: 'UA-100821198-4',
  },
});

export default (key, criteria = {}) => store.get(key, { env, ...criteria });
